import {Serializable} from './serializable.abstract';

export class ClientDTO extends Serializable {
  id: number;
  internalId: number;
  additionalInformation: string;
  additionalInformationLunchMenu: string;
  availability: {
    status: number;
    title: string;
    description: string;
    nonOpeningDays: Date[];
  };
  textElements: {
    salutations: {
      morning: string;
      midday: string;
      afternoon: string;
      evening: string;
    };
  };
  features: {
    lunchMenu: boolean;
    branches: boolean;
    allergens: boolean;
    delivery: boolean;
    sellUnit: boolean;
    manualOrder: boolean;
    articleAttributes: boolean;
    downloadMenu: boolean;
  };
  delivery: {
    active: boolean;
    interval: number;
    notificationTime: string;
    notificationTitle: string;
    notificationText: string;
  };
  adminAppVersion: string;

  public override deserialize(input: any): Serializable {
    return super.deserialize(input);
  }
}

export class Client extends ClientDTO {

}
